
import { Vue, Options } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import firstCapitalize from "@/app/infrastructures/misc/common-library/FirstCapitalize";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { PromoConfiguration } from "@/domain/entities/PromoConfiguration";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import { PROMO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";
import { flags } from "@/feature-flags";
import { NestedCheckboxItem } from "@/domain/entities/MainApp";
import startCase from "lodash/startCase";

@Options({
  components: {
    DetailLayout
  },
  computed: {}
})
export default class DetailPromo extends Vue {
  mounted() {
    this.fetchDetail();
  }

  get id(): string {
    return this.$route.params.id.toString();
  }

  goBack() {
    this.$router.push("/tarif/promo-configuration");
  }

  goToEdit() {
    this.$router.push(`/tarif/promo-configuration/edit/${this.id}`);
  }

  goToAddDuplicate() {
    this.$router.push(`/tarif/promo-configuration/add`);
  }

  onChipStatus(status: string) {
    switch (status) {
      case "expired":
        return {
          style: "bg-red-lp-600 text-red-lp-300",
          value: "Expired"
        };
      case "active":
        return {
          style: "bg-green-lp-300 text-green-lp-400",
          value: "Active"
        };
      case "pending":
        return {
          style: "bg-yellow-lp-200 text-yellow-lp-100",
          value: "Pending"
        };
      case "canceled":
        return {
          style: "bg-gray-lp-200 text-black-lp-300",
          value: "Dibatalkan"
        };
      case "nearly-expired":
        return {
          style: "bg-yellow-lp-500 text-white",
          value: "Nearly Expired"
        };
      default:
        break;
    }
  }

  promoQuantifier(promo: string) {
    switch (promo) {
      case "total_tariff":
        return "Total Tariff";
      case "publish_rate":
        return "Publish Rate";
      case "publish_rate,shipping_surcharge":
        return "Publish Rate + Shipping Surcharge";
      case "publish_rate,shipping_surcharge,origin_forward_rate,destination_forward_rate":
        return "Publish Rate + Shipping Surcharge + Origin Forward Rate + Destination Forward Rate";
      default:
        break;
    }
  }

  formatDate(date: any) {
    return formatDateWithoutTime(date);
  }

  firstCapitalize(text: string) {
    return firstCapitalize(text);
  }

  get isShowEditButton() {
    return (
      this.detailPromoConfiguration.promoConfigurationStatus === "active" ||
      this.detailPromoConfiguration.promoConfigurationStatus === "pending" ||
      this.detailPromoConfiguration.promoConfigurationStatus ===
        "nearly-expired"
    );
  }

  get isShowDuplicateButton() {
    return this.detailPromoConfiguration.promoConfigurationStatus !== "pending";
  }

  get isShowCancelButton() {
    return this.detailPromoConfiguration.promoConfigurationStatus === "pending";
  }

  // data detail
  isLoading = false;
  errorCause = "";
  detailPromoConfiguration = new PromoConfiguration();
  async fetchDetail() {
    this.errorCause = "";
    this.isLoading = true;
    try {
      this.detailPromoConfiguration = await PromoConfigurationController.getDetailPromoConfiguration(
        Number(this.id)
      );
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }

  get isEditAble() {
    return checkRolePermission(PROMO_CONFIGURATION.EDIT);
  }
  get isDuplicateAble() {
    return checkRolePermission(PROMO_CONFIGURATION.DUPLICATE);
  }
  get isCancelAble() {
    return checkRolePermission(PROMO_CONFIGURATION.CANCEL);
  }

  get shipmentOptions() {
    // const listData = this.detailPromoConfiguration.pdcShipmentOption.map((item: any) => item);
    const pdcShipmentOption = flags.feature_pdc_list_shipment.getValue().split(",");
    return pdcShipmentOption;
  }

  get shipmentList() {
    // const listData = this.detailPromoConfiguration.pdcShipmentOption.map((item: any) => item);
    const shipmentTrue = [] as any;
    this.detailPromoConfiguration.promoConfigurationShipmentOption.map((item) => {
      if(item.value === true) {
        shipmentTrue.push(item.name)
      }
    });

    return this.shipmentOptions.map(
      (shipment: any) =>
        new NestedCheckboxItem({
          label: shipment,
          textSize: 14,
          keyName: shipment,
          disabled: true,
          value: shipmentTrue.includes(shipment)
        })
    );
  }

  startCase(value: string) {
    return  startCase(value);
  }
}
