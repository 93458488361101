
import { Vue } from "vue-class-component";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import {
  PromoConfigurationList,
  PromoConfigurationListData
} from "@/domain/entities/PromoConfiguration";
import { Pagination } from "@/domain/entities/Pagination";
import { PromoConfigurationListRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { PROMO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";
import startCase from "lodash/startCase";

export default class ListPromoConfiguration extends Vue {
  get paginationStyle() {
    return "v3";
  }

  goToCreate() {
    this.$router.push("/tarif/promo-configuration/add");
  }

  onClickRow(item: PromoConfigurationListData) {
    this.$router.push(
      `/tarif/promo-configuration/${item.promoConfigurationId}`
    );
  }

  mounted() {
    this.getPromoConfigurationList();
  }

  isFirstRequest = true;
  isLoading = false;
  errorCause = "";
  status = "";
  category = "";
  search = "";
  listData = new PromoConfigurationList(new Pagination(1, 20), []);

  async getPromoConfigurationList() {
    this.isLoading = true;
    this.errorCause = "";
    try {
      this.listData = await PromoConfigurationController.getPromoConfigurationList(
        new PromoConfigurationListRequest({
          page: this.listData.pagination.page,
          limit: this.listData.pagination.limit,
          name: this.search,
          status: this.status,
          category: this.category
        })
      );
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }

  get pagination() {
    return this.listData.pagination;
  }

  columns = [
    {
      name: "ID",
      styleHead: "w-10 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: PromoConfigurationListData, index: number) => {
        return `<div class='text-left text-black-lp-300'>${item.promoConfigurationId}</div>`;
      }
    },
    {
      name: "Nama Promo",
      styleHead: "w-56 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: PromoConfigurationListData) => {
        return `<div class='text-left text-black-lp-300 break-all'>${item.promoConfigurationName}</div>`;
      }
    },
    {
      name: "Jenis Promo",
      styleHead: "w-40 text-left",
      render: (item: PromoConfigurationListData) => {
        return `<div class='text-left text-black-lp-300 break-all'>${startCase(item.promoConfigurationCategory)}</div>`;;
      }
    },
    {
      name: "Periode Promo",
      styleHead: "w-56 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: PromoConfigurationListData) => {
        return `<div class='text-left text-black-lp-300 break-all'>${formatDateWithoutTime(
          item.promoConfigurationStartDate
        )} - ${formatDateWithoutTime(item.promoConfigurationEndDate)}</div>`;
      }
    },
    {
      name: "Terakhir diubah",
      styleHead: "w-56 text-left whitespace-no-wrap",
      styleBody: "text-center",
      render: (item: PromoConfigurationListData) => {
        return `<div class='flex text-black-lp-300'>${formatDate(
          item.promoConfigurationUpdatedAt
        )}</div>
        <div class="capitalize text-gray-lp-500">${
          item.promoConfigurationUpdatedName
        }</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "w-40 text-left",
      render: (item: PromoConfigurationListData) => {
        return `<div class="rounded-full px-2 py-0 text-center ${this.convertStatus(item.promoConfigurationStatus?.toLowerCase())}">
        <div class="overflow-ellipsis text-left capitalize">${item.promoConfigurationStatus}</div></div>`;
      }
    }
  ];

  convertStatus(status: string) {
    const statuses: any = {
      "active": "bg-green-lp-300 text-green-lp-200",
      "pending": "bg-yellow-lp-200 text-yellow-lp-100",
      "expired": "bg-red-300 text-red-lp-200",
      "cancalled": "bg-gray-lp-200 text-gray-lp-300",
      "nearly-expired": "bg-yellow-lp-100 text-yellow-200"
    }

    return statuses[status];
  }

  get listStatus() {
    return [
      { name: "Pilih Status", value: "" },
      { name: "Active", value: "active" },
      { name: "Cancelled", value: "cancelled" },
      { name: "Pending", value: "pending" },
      { name: "Nearly Expired", value: "nearly-expired" },
      { name: "Expired", value: "expired" }
    ];
  }
  isOpenSelectStatus = false;

  onOpenSelectStatus() {
    this.isOpenSelectStatus = true;
  }
  onCloseSelectStatus() {
    this.isOpenSelectStatus = false;
  }

  onSelectStatus(name: string, value: string) {
    this.setFirstRequest(false);
    this.setFirstPage();
    this.status = value;
    this.getPromoConfigurationList();
    this.onCloseSelectStatus();
  }

  onSearch(value: string) {
    this.setFirstRequest(false);
    this.setFirstPage();
    this.search = value;
    this.getPromoConfigurationList();
  }

  clearSearch() {
    this.setFirstRequest(false);
    this.setFirstPage();
    this.search = "";
    this.getPromoConfigurationList();
  }

  setFirstPage() {
    this.listData.pagination.page = 1;
  }

  setFirstRequest(status: boolean) {
    this.isFirstRequest = status;
  }

  get isCreateAble() {
    return checkRolePermission(PROMO_CONFIGURATION.CREATE);
  }
  get isDetailAble() {
    return checkRolePermission(PROMO_CONFIGURATION.DETAIL);
  }

  // promo
  get listPromoType() {
    return [
      { name: "Pilih Jenis Promo", value: "" },
      { name: "Tactical", value: "tactical" },
      { name: "Campaign", value: "campaign" }
    ];
  }
  isOpenSelectPromoType = false;

  onOpenSelectPromoType() {
    this.isOpenSelectPromoType = true;
  }
  onCloseSelectPromoType() {
    this.isOpenSelectPromoType = false;
  }

  onSelectPromoType(name: string, value: string) {
    this.setFirstRequest(false);
    this.setFirstPage();
    this.category = value;
    this.getPromoConfigurationList();
    this.onCloseSelectPromoType();
  }
}
