import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "\n      bg-white\n      flex flex-col\n      text-12px\n      xxl:text-14px\n      overflow-hidden\n      h-screen\n      relative\n      w-full\n    " }
const _hoisted_2 = { class: "flex gap-x-4" }
const _hoisted_3 = { class: "\n            font-semibold\n            text-20px\n            whitespace-no-wrap\n            text-black-lp-200\n            capitalize\n          " }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Select = _resolveComponent("Select")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.$route?.meta.name), 1),
          _createVNode(_component_Search, {
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.search,
            class: "w-300px",
            placeholder: "Cari Promo"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_Select, {
            class: "w-48",
            onOpenSelect: _ctx.onOpenSelectPromoType,
            onCloseSelect: _ctx.onCloseSelectPromoType,
            isOpenMenu: _ctx.isOpenSelectPromoType,
            value: _ctx.category,
            onChooseSelect: _ctx.onSelectPromoType,
            dataSelect: _ctx.listPromoType
          }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"]),
          _createVNode(_component_Select, {
            class: "w-48",
            onOpenSelect: _ctx.onOpenSelectStatus,
            onCloseSelect: _ctx.onCloseSelectStatus,
            isOpenMenu: _ctx.isOpenSelectStatus,
            value: _ctx.status,
            onChooseSelect: _ctx.onSelectStatus,
            dataSelect: _ctx.listStatus
          }, null, 8, ["onOpenSelect", "onCloseSelect", "isOpenMenu", "value", "onChooseSelect", "dataSelect"])
        ]),
        (_ctx.isCreateAble)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              title: "Buat Promo",
              textColor: "white",
              onClick: _ctx.goToCreate
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.listData.data.length && _ctx.isFirstRequest && !_ctx.isLoading && !_ctx.errorCause && _ctx.listData.pagination.page === 1)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          data: "Promo Diskon"
        }))
      : (_openBlock(), _createBlock(_component_TableV2, {
          key: 1,
          columns: _ctx.columns,
          data: _ctx.listData.data,
          loading: _ctx.isLoading,
          pagination: _ctx.pagination,
          "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
          isRightFreeze: "",
          totalColumnFreeze: 1,
          onRequest: _ctx.getPromoConfigurationList,
          onTryAgain: _ctx.getPromoConfigurationList,
          onClick: _ctx.onClickRow,
          errorCause: _ctx.errorCause,
          isDetailAble: _ctx.isDetailAble,
          paginationStyle: _ctx.paginationStyle
        }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "onTryAgain", "onClick", "errorCause", "isDetailAble", "paginationStyle"]))
  ]))
}