
import { Vue, Options } from "vue-class-component";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { ModalMessageEntities, NestedCheckboxItem, OptionsClass } from "@/domain/entities/MainApp";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import parseInputFloatToFloat from "@/app/infrastructures/misc/common-library/ParseInputFloatToFloat";
import formatInputNumberOnly from "@/app/infrastructures/misc/common-library/FormatInputNumberOnly";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { SubmitPromoConfigurationApiRequest } from "@/data/payload/api/PromoConfigurationApiRequest";
import { PromoConfigurationController } from "@/app/ui/controllers/PromoConfigurationController";
import formatInputDecimal from "@/app/infrastructures/misc/common-library/FormatInputDecimal";
import { flags } from "@/feature-flags";
import { FlagsPromoDiscount } from "@/feature-flags/flags-promo-discount"

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.answerLeavingPage) {
      next();
    } else {
      this.leavePageConfirmation = true;
      this.nextPath = to.path;
      next(false);
    }
  }
})
export default class PromoConfigurationForm extends Vue {
  mounted() {
    this.initFormPromoConfiguration();
  }

  initFormPromoConfiguration() {
    if (!this.isFormTypeAdd) {
      this.fetchDetail();
    }
  }

  // detail
  isLoading = false;
  errorCause = "";
  async fetchDetail() {
    this.errorCause = "";
    this.isLoading = true;
    try {
      const detailPromoConfig = await PromoConfigurationController.getDetailPromoConfiguration(
        Number(this.$route.params.id)
      );
      this.form = new SubmitPromoConfigurationApiRequest({
        promoId: detailPromoConfig.promoConfigurationId,
        promoName: detailPromoConfig.promoConfigurationName,
        promoType: this.listPromoType.find(
          (item: OptionsClass) =>
            item.value === detailPromoConfig.promoConfigurationDiscountType
        ),
        promoQuantifier: this.listPromoQuantifier.find(
          (item: OptionsClass) =>
            item.value ===
            detailPromoConfig.promoConfigurationParameterCalculation
        ),
        statusPromo: detailPromoConfig.promoConfigurationStatus,
        startDate: new Date(detailPromoConfig.promoConfigurationStartDate),
        startDateFromDetail: new Date(
          detailPromoConfig.promoConfigurationStartDate
        ),
        endDate: new Date(detailPromoConfig.promoConfigurationEndDate),
        promoAmount: detailPromoConfig.promoConfigurationPromoDiscount.toString(),
        maximumDiscount: detailPromoConfig.promoConfigurationMaximalPromo.toLocaleString(
          "id"
        ),
        products: detailPromoConfig.promoConfigurationProductType.split(","),
        minimumWeight: Number(detailPromoConfig.promoConfigurationMinimalGrossWeight),
        maximumWeight: Number(detailPromoConfig.promoConfigurationMaximalGrossWeight),
        originCities: this.detailPromoConfigSpliting(detailPromoConfig.promoConfigurationOrigin),
        destinationCities: this.detailPromoConfigSpliting(detailPromoConfig.promoConfigurationDestination),
        appliedToRetail: detailPromoConfig.promoConfigurationAppliedList.includes('retail'),
        appliedToShipment: detailPromoConfig.promoConfigurationShipmentOption.filter((shipment) => shipment.value).map((shipment) => shipment.name),
        referenceWeight: this.listReferenceWeight.find((referenceWeight) => referenceWeight.value === detailPromoConfig.pdcPromoReferenceWeight),
        promoUnique: this.listOfPromoUnique.find((item: OptionsClass) => item.value === detailPromoConfig.promoConfigurationType),
      });
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }

  detailPromoConfigSpliting(detailPromoConfig: any) {
    return detailPromoConfig.split(",")
      .map((city: any) => {
        const parsedCity = city.split(" - ");
        const cityCode = parsedCity[0];
        const cityName = this.convertCityPayload(parsedCity, cityCode);
        return {
          name: cityName,
          value: cityCode
        };
      })
  }

  convertCityPayload(parsedCity: Array<any>, cityCode: string) {
    if (parsedCity[0] === "ALL") {
      return "Semua";
    } else {
      const parsedCityNew = parsedCity[1] ? ` - ${parsedCity[1]}` : "";
      return `${cityCode}${parsedCityNew}`;
    }
  }

  get titlePage() {
    return this.$route.meta.title;
  }
  get isFormTypeAdd() {
    return this.$route.meta.formType === "add";
  }

  // route logic
  leavePageConfirmation = false;
  saveConfirmation = false;
  nextPath = "";
  answerLeavingPage = false;
  goBack() {
    let path = "/tarif/promo-configuration";
    if (!this.isFormTypeAdd) {
      path += `/${this.$route.params.id}`;
    }
    this.$router.push(path);
  }
  onLeavePage() {
    this.answerLeavingPage = true;
    this.$router.push(this.nextPath);
  }
  onCloseSuccess() {
    MainAppController.closeMessageModal();
    this.answerLeavingPage = true;
    this.goBack();
  }

  // city
  fetchCity(search: string) {
    LocationController.getCityList({
      search: search,
      status: "active",
      page: 1,
      limit: 10
    });
  }
  filterCity = debounce((search: string) => {
    if (search.length > 2 || !search) this.fetchCity(search);
  }, 250);
  get cityOptions() {
    return [
      {
        name: "Semua",
        value: "ALL"
      },
      ...LocationController.cityData.cityData.map(e => ({
        id: e.id,
        name: `${e.code} - ${e.name}`,
        value: `${e.code}`
      }))
    ];
  }
  get isLoadingCity() {
    return LocationController.isLoading;
  }

  // date
  get propsStartDate() {
    return {
      minDate: this.$moment()
        .add(1, "day")
        .toDate()
    };
  }
  get propsEndDate() {
    return {
      disabled: !this.form.startDate || this.formDisabled("endDate"),
      minDate:
        this.form.startDate ||
        this.$moment()
          .add(1, "day")
          .toDate()
    };
  }
  onSelectStartDate(value: Date) {
    this.form.startDate = value;
    if (this.form.startDate > this.form.endDate) {
      this.form.endDate = "";
    }
  }
  onSelectEndDate(value: Date) {
    this.form.endDate = value;
  }

  // promo type
  get listPromoType() {
    return [
      {
        name: "Percentage",
        value: "percentage"
      },
      {
        name: "Flat",
        value: "flat"
      }
    ];
  }
  onSelectPromoType() {
    this.form.promoAmount = "";
    this.form.maximumDiscount = "";
    this.form.minimumTransaction = "";
  }

  // quantifier promo
  get listPromoQuantifier() {
    return [
      {
        name: "Total Tariff",
        value: "total_tariff"
      },
      {
        name: "Publish Rate",
        value: "publish_rate"
      },
      {
        name: "Publish Rate + Shipping Surcharge",
        value: "publish_rate,shipping_surcharge"
      },
      {
        name:
          "Publish Rate + Shipping Surcharge + Origin Forward Rate + Destination Forward Rate",
        value:
          "publish_rate,shipping_surcharge,origin_forward_rate,destination_forward_rate"
      }
    ];
  }

  get disableReferenceWeightAndAppliedTo() {
    return FlagsPromoDiscount.disable_reference_weight_and_applied_to.isEnabled()
  }

  // reference weight
  get listReferenceWeight() {
    return [
      new OptionsClass({
        name: "Gross Weight",
        value: "gross_weight",
      }),
      new OptionsClass({
        name: "Volume Weight",
        value: "volume_weight",
      }),
      new OptionsClass({
        name: "Chargeable Weight",
        value: "chargeable_weight",
      }),
    ];
  }

  // shipment list apllied for
  get shipmentList() {
    return flags.feature_pdc_list_shipment.getValue().split(",");
  }

  get parentShipmentCheckbox() {
    const isAllShipmentChecked = this.shipmentList.every((shipment) => this.form.appliedToShipment.includes(shipment))
    return new NestedCheckboxItem({
      label: "Shipment :",
      textSize: 14,
      keyName: "shipment",
      disabled: this.formDisabled("appliedToShipment"),
      value: isAllShipmentChecked
    });
  }

  get childShipmentCheckbox() {
    return this.shipmentList.map(
      (shipment: string) =>
        new NestedCheckboxItem({
          label: shipment,
          textSize: 14,
          keyName: shipment,
          disabled: this.formDisabled("appliedToShipment"),
          value: this.form.appliedToShipment.includes(shipment)
        })
    );
  }

  onCheckAllShipment(isChecked: boolean) {
    this.form.appliedToShipment = isChecked ? [...this.shipmentList] : [];
  }

  onCheckShipment(shipment: NestedCheckboxItem) {
    const indexOfShipment = this.form.appliedToShipment.indexOf(
      shipment.keyName
    );
    indexOfShipment >= 0
      ? this.form.appliedToShipment.splice(indexOfShipment, 1)
      : this.form.appliedToShipment.push(shipment.keyName);
  }

  onUpdateAppliedToRetail(value: boolean) {
    this.form.appliedToRetail = value;
  }
  // form
  form = new SubmitPromoConfigurationApiRequest({
    promoType: new OptionsClass(this.listPromoType[0]),
    appliedToRetail: this.disableReferenceWeightAndAppliedTo,
    referenceWeight: this.disableReferenceWeightAndAppliedTo
      ? this.listReferenceWeight[0]
      : new OptionsClass(),
  });
  get formMinimumWeight() {
    return Number(this.form.minimumWeight);
  }
  get formMaximumWeight() {
    return Number(this.form.maximumWeight);
  }
  get formPromoAmount() {
    return parseInputFloatToFloat(this.form.promoAmount);
  }
  get isFormPromoTypeIsPercentage() {
    return this.form.promoType.value === this.listPromoType[0].value;
  }
  get isFormPromoTypeIsFlat() {
    return this.form.promoType.value === this.listPromoType[1].value;
  }

  // form validation
  formatInputFloat(value: string) {
   return formatInputDecimal(value);
  }
  formatInputNumberOnly(value: string) {
    return formatInputNumberOnly(value);
  }
  formatInputNumberAbleZero(value: string) {
    return formatInputNumberOnly(value, true);
  }
  get discountValidity() {
    return {
      error: this.formPromoAmount > 100,
      errorCaption: "Diskon tidak boleh melebihi 100%"
    };
  }
  get weightValidity() {
    if (this.formMinimumWeight > this.formMaximumWeight) {
      return {
        error: true,
        errorCaption: "Berat Maksimal harus lebih besar dari Berat Minimal"
      };
    }
    return this.maximumWeightValidity;
  }
  maximumWeightValidity = {
    error: false,
    errorCaption: ""
  }
  changeMaximumWeightValidity(val: any) {
    this.maximumWeightValidity.error = val <= 0;
    this.maximumWeightValidity.errorCaption = val <= 0 ? "Berat Maksimal tidak boleh 0" : "";
  }
  minimumWeightValidity = {
    error: false,
    errorCaption: ""
  };
  changeMinimumWeightValidity(val: any) {
    this.minimumWeightValidity.error = val <= 0;
    this.minimumWeightValidity.errorCaption = "Berat Minimal tidak boleh 0";
  }
  get isFormPromoTypePercentageValid() {
    return this.isFormPromoTypeIsPercentage
      ? !!this.form.promoAmount &&
          !this.discountValidity.error &&
          !!this.form.promoQuantifier.value
      : true;
  }
  get isFormPromoTypeFlatValid() {
    return this.isFormPromoTypeIsFlat ? !!this.form.promoAmount : true;
  }
  get isFormValid() {
    return (
      !!this.form.promoName &&
      !!this.form.originCities.length &&
      !!this.form.destinationCities.length &&
      !!this.form.products.length &&
      !!this.formMinimumWeight &&
      !!this.formMaximumWeight &&
      !this.weightValidity.error &&
      !this.maximumWeightValidity.error &&
      !!this.form.startDate &&
      !!this.form.endDate &&
      !!this.form.promoType.value &&
      !!this.form.promoUnique.value &&
      this.isFormPromoTypePercentageValid &&
      this.isFormPromoTypeFlatValid &&
      !!this.form.referenceWeight &&
      (this.form.appliedToRetail || !!this.form.appliedToShipment.length)
    );
  }
  async onSaveForm() {
    MainAppController.closeMessageModal();
    MainAppController.closeErrorMessage();
    MainAppController.showLoading();
    try {
      if (this.isFormTypeAdd) {
        await PromoConfigurationController.createPromoConfiguration(this.form);
      } else {
        await PromoConfigurationController.editPromoConfiguration(this.form);
      }
      MainAppController.showMessageModal(
        new ModalMessageEntities({
          title: this.isFormTypeAdd
            ? "Pembuatan Promo Baru Berhasil !"
            : "Perubahan Promo Berhasil !",
          textSuccess: "OK",
          onSubmit: () => this.onCloseSuccess(),
          image: "image-modal-success"
        })
      );
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          error,
          `${!this.isFormTypeAdd ? "Perubahan" : "Pembuatan"} Promo Gagal!`,
          () => this.onSaveForm()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  // logic disabled form by status
  formDisabled(key: string) {
    if (
      (key === "appliedToRetail" ||
        key === "appliedToShipment" ||
        key === "referenceWeight") &&
      this.disableReferenceWeightAndAppliedTo
    ) {
      return this.disableReferenceWeightAndAppliedTo;
    }
    if (this.isFormTypeAdd) {
      return false
    };
    if (this.form.statusPromo === "pending") {
      return false;
    } else {
      return key !== "endDate";
    }
  }

  get listOfPromoUnique() {
    return [{
      name: "Tactical",
      value: "tactical",
    }, {
      name: "Campaign",
      value: "campaign",
    }];
  }
}
